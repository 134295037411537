/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$bodyfont: 'Gotham', sans-serif;
$headingfont: 'Gotham', sans-serif;

$primary: #400101;
$secondary: #DDDDDD;
$tertiary: #BF0D1C;

$bodycolor: $secondary;
$bodytext: #1D1D1D;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$gray-light: #E0E0E0;

$themecolor: #1e88e5;

/*bootstrap Color*/
$danger: #FD2929;
$success: #39743c;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;

//PALETTE
$mat-primary: (
  50: #ffdec9,
  100: #f4b9a9,
  200: #d19183,
  300: #ae6b5b,
  400: #944e3f,
  500: #7a3223,
  600: #6f291e,
  700: #5f1e16,
  800: #501010,
  900: #400101,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
$mat-secondary: (
  50: #f9f9f9,
  100: #f4f4f4,
  200: #ececec,
  300: #dddddd,
  400: #bababa,
  500: #9a9a9a,
  600: #727272,
  700: #5e5e5e,
  800: #3f3f3f,
  900: #1e1e1e,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
