.page {
  &-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }

  &-primary {
    background: black;
    color: white;
    font-size: 20px;
    font-weight: 400;
  }

  &-divider {
    width: 100%;
    height: 1px;
    background: #C6B3B3;
    margin: 20px 0;
  }
}
