// normalaize

* {
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: $bodyfont;
  margin: 0;
  overflow-x: hidden;
  color: $bodytext;
  font-weight: 400;
  background: $bodycolor;
}

a:hover,
a:focus,
a {
  text-decoration: none;
}

hr {
  border: 0px;
  height: 1px;
  background: $border;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: $danger;
  background-color: $light-danger;
  border-radius: 0.25rem;
}

button{
  line-height: 47px !important;
  border-radius: var(--boder-radius-xlg, 32px) !important;
}


