/* You can add global styles to this file, and also import other style files */

html, body {
  min-height: 100%;
}

body {
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
}

@import 'front/variable';
@import 'front/theme';
@import 'front/fonts';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/normalize';
@import 'front/component/page';

@import 'front/responsive';

.d-none{
  display: none;
}
